import { jsxs, jsx } from "react/jsx-runtime";
import { createState } from "@tecnobit-srl/state";
import { useIsSsr } from "@tecnobit-srl/hook-use-is-ssr";
import { useMemo, useEffect } from "react";
import { createPortal } from "react-dom";
const { ContextProvider, useStore } = createState(
  "Head",
  (set) => ({
    elements: {},
    add: (key, element) => {
      set((state) => ({
        ...state,
        elements: {
          ...state.elements,
          [key]: element
        }
      }));
    },
    remove: (key) => {
      set((state) => {
        const { [key]: _, ...rest } = state.elements;
        return {
          ...state,
          elements: rest
        };
      });
    }
  })
);
function HeadProvider({ children }) {
  return /* @__PURE__ */ jsxs(ContextProvider, { children: [
    children,
    /* @__PURE__ */ jsx(HeadElements, {})
  ] });
}
function HeadElements() {
  const elements = useStore((s) => s.elements);
  const nodes = useMemo(() => Object.values(elements), [elements]);
  const isSsr = useIsSsr();
  if (isSsr) {
    return null;
  }
  return createPortal(nodes, document.head);
}
function Head({
  children,
  headKey
}) {
  const { add, remove } = useStore((s) => ({ add: s.add, remove: s.remove }));
  useEffect(() => {
    add(headKey, children);
    return () => {
      remove(headKey);
    };
  }, [headKey, children, add, remove]);
  const ssrStyle = useMemo(
    () => ({
      display: "none"
    }),
    []
  );
  const isSsr = useIsSsr();
  if (isSsr) {
    return /* @__PURE__ */ jsx("span", { style: ssrStyle, className: "ssr-head-elements", children });
  }
  return null;
}
export {
  Head,
  HeadProvider
};
